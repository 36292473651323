/***********************************************/
// Capa de Servicios para el modulo de Medicos.

import axios from "axios";
import store from "@/store/store.js";

export default new (class {
  #baseUrl = null;
  errormsg = "";
  message = null;
  header = {
    headers: {
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
      Authorization: "Bearer " + store.getters.getToken,
    },
  };

  constructor() {
    // Inicializar campos y propiedades.
    this.#baseUrl = store.getters.getBaseURL;
  }

  async getAllRecords() {
    try {
      let response = await axios.get(this.#baseUrl + "/doctors");
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("response.data:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getData() {
    try {
      let response = await axios.get(this.#baseUrl + "/view/doctors");
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewList() {
    try {
      let response = await axios.get(this.#baseUrl + "/view-list/doctors");
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getViewListByNumber() {
    try {
      let response = await axios.get(
        this.#baseUrl + "/view-list-number/doctors"
      );
      // console.log('response: ', response);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getRecord(id) {
    try {
      let response = await axios.get(`${this.#baseUrl}/doctors/${id}`);
      if (response.data.success) {
        // console.log("records:", response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async addRecord(formData) {
    // console.log('Adding Record.', formData);
    try {
      let specialtiesList = formData.specialties;
      let recordData = new FormData();
      recordData.append("id", formData.id);
      recordData.append("name", formData.name);
      recordData.append("register_number", formData.register_number);
      recordData.append("description", formData.description);
      recordData.append("description_english", formData.description_english);
      recordData.append("address", formData.address);
      recordData.append("email", formData.email);
      recordData.append("phone1", formData.phone1);
      recordData.append("phone2", formData.phone2);
      recordData.append("map_lat", formData.map_lat);
      recordData.append("map_lng", formData.map_lng);
      recordData.append("city_id", formData.city_id);
      recordData.append("web_page", formData.web_page);
      recordData.append(
        "profesional_license_number",
        formData.profesional_license_number
      );
      recordData.append(
        "specialty_license_number",
        formData.specialty_license_number
      );
      recordData.append(
        "certification_validity",
        formData.certification_validity
      );
      recordData.append("certificate_number", formData.certificate_number);
      recordData.append(
        "professional_license_verification",
        formData.professional_license_verification
      );
      recordData.append("facebook_profile", formData.facebook_profile);
      recordData.append("twitter_profile", formData.twitter_profile);
      recordData.append("instagram_profile", formData.instagram_profile);
      recordData.append("youtube_video", formData.youtube_video);
      recordData.append("membership", formData.membership);
      recordData.append("servicehours", formData.servicehours);
      // console.log('Record-Data: ', recordData);
      let response = await axios.post(this.#baseUrl + "/doctors", recordData);
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        let doctor_id = response.data.id;
        // this.uploadImage(doctor_id, picture_id, imageFile);

        // Sincroniza las especialidades con el medico
        let specialtiesIds = [];
        specialtiesList.forEach((item) => {
          specialtiesIds.push(item.id);
        });
        this.registerSpecialties(doctor_id, specialtiesIds);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  // Pushes posts to the server when called.
  async updateRecord(formData) {
    // console.log('Array de Especialidades: ', formData.specialties);
    try {
      let specialtiesList = formData.specialties;
      let recordData = new FormData();
      recordData.append("id", formData.id);
      recordData.append("name", formData.name);
      recordData.append("register_number", formData.register_number);
      recordData.append("description", formData.description);
      recordData.append("description_english", formData.description_english);
      recordData.append("address", formData.address);
      recordData.append("email", formData.email);
      recordData.append("phone1", formData.phone1);
      recordData.append("phone2", formData.phone2);
      recordData.append("map_lat", formData.map_lat);
      recordData.append("map_lng", formData.map_lng);
      recordData.append("city_id", formData.city_id);
      recordData.append("web_page", formData.web_page);
      recordData.append(
        "profesional_license_number",
        formData.profesional_license_number
      );
      recordData.append(
        "specialty_license_number",
        formData.specialty_license_number
      );
      recordData.append(
        "certification_validity",
        formData.certification_validity
      );
      recordData.append("certificate_number", formData.certificate_number);
      recordData.append(
        "professional_license_verification",
        formData.professional_license_verification
      );
      recordData.append("facebook_profile", formData.facebook_profile);
      recordData.append("twitter_profile", formData.twitter_profile);
      recordData.append("instagram_profile", formData.instagram_profile);
      recordData.append("youtube_video", formData.youtube_video);
      recordData.append("membership", formData.membership);
      recordData.append("servicehours", formData.servicehours);
      recordData.append("_method", "put");
      // console.log('Record-Data: ', recordData);
      let response = await axios.post(
        `${this.#baseUrl}/doctors/${formData.id}`,
        recordData
      );
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        let doctor_id = response.data.id;
        // this.uploadImage(doctor_id, picture_id, imageFile);

        // Sincroniza las especialidades con el medico
        let specialtiesIds = [];
        specialtiesList.forEach((item) => {
          specialtiesIds.push(item.id);
        });
        this.registerSpecialties(doctor_id, specialtiesIds);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteRecord(id) {
    try {
      // console.log('Deleting Record: %s ', id);
      let response = await axios.delete(`${this.#baseUrl}/doctors/${id}`);
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async registerSpecialties(doctor_id, specialtiesIds) {
    try {
      let formData = new FormData();
      formData.append("doctor_id", doctor_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/register-specialties/doctors",
        formData
      );
      if (response.data.success) {
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async deleteSpecialties(doctor_id, specialtiesIds) {
    try {
      let formData = new FormData();
      formData.append("doctor_id", doctor_id);
      formData.append("specialties_ids", specialtiesIds);
      let response = await axios.post(
        this.#baseUrl + "/delete-specialties/doctors",
        formData
      );
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      return this.handleError(error);
    }
  }

  async uploadImage(doctor_id, picture_id, imageFile) {
    if (imageFile) {
      try {
        let formData = new FormData();
        formData.append("doctor_id", doctor_id);
        formData.append("picture_id", picture_id);
        formData.append("file", imageFile);
        let response = await axios.post(
          this.#baseUrl + "/upload-image/doctors",
          formData
        );
        if (response.data.success) {
          // console.log('Respuesta: ', response.data);
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async deleteImage(doctor_id, picture_id) {
    if (picture_id) {
      try {
        let formData = new FormData();
        formData.append("doctor_id", doctor_id);
        formData.append("picture_id", picture_id);
        let response = await axios.post(
          this.#baseUrl + "/delete-image/doctors",
          formData
        );
        if (response.data.success) {
          // console.log('Respuesta: ', response.data);
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return this.handleError(error);
      }
    }
  }

  async sendEmail(profile_id, email) {
    try {
      let response = await axios.get(
        `${this.#baseUrl}/send-email/doctors?id=${profile_id}&email=${email}`
      );
      // console.log('Respuesta: ', response);
      if (response.data.success) {
        // console.log('Respuesta: ', response.data);
        return response.data;
      } else {
        return null;
      }
    } catch (error) {
      let result;
      result = this.handleError(error);
      return result;
    }
  }

  handleError(error) {
    let valueError;
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      // console.log('Error response: %s', error.response.data.message);
      valueError = error.response.data.messaje;
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      valueError = error.request.data;
      // console.log(error.request);
      // console.error('Error request: ', error.request.data);
    } else {
      // Something happened in setting up the request and triggered an Error
      valueError = error.message;
      // console.log('Error', error.message);
    }
    // console.log(error.config);
    return valueError;
  }
})();

/*let data = {
    id: formData.id,
    name: formData.name,
    specialties: formData.specialties
};
console.log('Record-Data a postear: ', data);*/
// axios.post(this.#baseUrl + '/update-record/hospitals', data)

// let comprob = Array.isArray(specialtiesIds) ? 'Si es un array.' : 'No es un array';
// console.log('Es un array? ', comprob);
// console.log('Array de valores: ', specialtiesIds);
